<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <b-row>
          <b-col xl="12" md="12" sm="12" cols="12">
            <h1 class="text-primary my-1">Import Excel Purchase</h1>
            <b-row>
              <b-col cols="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <div>
                    <b-form-file accept=".xls, .xlsx" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." @change="importExcelFile" ref="file-input" />
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="6" xl="6" md="12" sm="12">
                <b-form-group class="mb-40">
                  <b-input-group>
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
                    <b-input-group-append>
                      <b-button @click="filter = ''" variant="primary">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              :items="listPurchases"
              :small="true"
              responsive
              selectable
              select-mode="single"
              style="padding-bottom:30px"
              class="position-relative "
              head-variant="dark"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :fields="inHeaders"
              striped
              hover
              :current-page="currentPage"
              :per-page="perPage"
              @row-selected="onRowSelected"
            >
              <template #cell(title)="data">
                <span>{{ data.item.title ? data.item.title : 'N/A' }}</span>
              </template>

              <template #cell(lienCustomerId)="data">
                <span>{{ data.item.lienCustomerId ? data.item.lienCustomerId : 'N/A' }}</span>
              </template>
              <template #cell(sellerCustomerId)="data">
                <span>{{ data.item.sellerCustomerId ? data.item.sellerCustomerId : 'N/A' }}</span>
              </template>
              <template #cell(sellerEmail)="data">
                <span>{{ data.item.sellerEmail ? data.item.sellerEmail : 'N/A' }}</span>
              </template>
              <template #cell(sellerName)="data">
                <span>{{ data.item.sellerName ? data.item.sellerName : 'N/A' }}</span>
              </template>
              <template #cell(sellerPhoneNumber)="data">
                <span>{{ data.item.sellerPhoneNumber ? data.item.sellerPhoneNumber : 'N/A' }}</span>
              </template>
              <template #cell(sellerType)="data">
                <b-badge v-if="data.item.sellerType == '1'" pill variant="light-primary" class="badge-glow">Individual</b-badge>
                <b-badge v-if="data.item.sellerType == '2'" pill variant="light-warning" class="badge-glow">Business</b-badge>
              </template>

              <template #cell(sellerContactPerson)="data">
                <span>{{ data.item.sellerContactPerson ? data.item.sellerContactPerson : 'N/A' }}</span>
              </template>
              <template #cell(sellerContactInfo)="data">
                <span>{{ data.item.sellerContactInfo ? data.item.sellerContactInfo : 'N/A' }}</span>
              </template>

              <template #cell(sellerTradeBalance)="data">
                <span>{{ data.item.sellerTradeBalance ? formatPrice(data.item.sellerTradeBalance, 2) : formatPrice(0) }}</span>
              </template>

              <template #cell(coOwnerCustomersId)="data">
                <span>{{ data.item.coOwnerCustomersId ? data.item.coOwnerCustomersId : 'N/A' }}</span>
              </template>
              <template #cell(coSellerName)="data">
                <span>{{ data.item.coSellerName ? data.item.coSellerName : 'N/A' }}</span>
              </template>

              <template #cell(lienHolder)="data">
                <span>{{ data.item.lienHolder ? data.item.lienHolder : 'N/A' }}</span>
              </template>
              <template #cell(approvedBy)="data">
                <span>{{ data.item.approvedBy ? data.item.approvedBy : 'N/A' }}</span>
              </template>
              <template #cell(totalMsrpOptions)="data">
                <span>{{ data.item.totalMsrpOptions ? data.item.totalMsrpOptions : 'N/A' }}</span>
              </template>
              <template #cell(accessories)="data">
                <span>{{ data.item.accessories ? data.item.accessories : 'N/A' }}</span>
              </template>

              <template #cell(priceContractType)="data">
                <b-badge v-if="data.item.priceContractType == 1" pill variant="light-success" class="badge-glow">Buy-in</b-badge>
                <b-badge v-if="data.item.priceContractType == 2" pill variant="light-info" class="badge-glow">Trade-in <feather-icon v-if="data.item.tradeStatus == 1 || data.item.tradeStatus == 2" icon="CheckCircleIcon" size="12"/></b-badge>
                <b-badge v-if="data.item.priceContractType == 3" pill variant="light-primary" class="badge-glow">Lease Return</b-badge>
              </template>
              <template #cell(creditIssued)="data">
                <span>{{ data.item.creditIssued ? formatPrice(data.item.creditIssued, 2) : formatPrice(0) }}</span>
              </template>
              <template #cell(gstRateVehicle)="data">
                <span>{{ data.item.gstRateVehicle ? data.item.gstRateVehicle : 'N/A' }}</span>
              </template>
              <template #cell(pstRateVehicle)="data">
                <span>{{ data.item.pstRateVehicle ? data.item.pstRateVehicle : 'N/A' }}</span>
              </template>
              <template #cell(payableBySeller)="data">
                <span>{{ data.item.payableBySeller ? formatPrice(data.item.payableBySeller, 2) : formatPrice(0) }}</span>
              </template>
              <template #cell(id)="data">
                <strong class="text-primary">#{{ Number(data.item.id) }}</strong>
              </template>
              <template #cell(quoteDate)="data">
                <span>{{ data.item.quoteDate ? data.item.quoteDate : 'N/A' }}</span>
              </template>
              <template #cell(completionDate)="data">
                <span>{{ data.item.completionDate ? data.item.completionDate : 'N/A' }}</span>
              </template>
              <template #cell(location)="data">
                <span>{{ data.item.location ? data.item.location : 'N/A' }}</span>
              </template>
              <template #cell(userId)="data">
                <span>{{ data.item.userId ? data.item.userId : 'N/A' }}</span>
              </template>
              <template #cell(condition)="data">
                <b-badge v-if="data.item.condition == 'NEW'" active variant="light-info" class="badge-glow ">New</b-badge>
                <b-badge v-if="data.item.condition == 'USED'" variant="light-success" class="badge-glow ">Used </b-badge>
              </template>
              <template #cell(status)="data">
                <b-badge v-if="data.item.status == 'H'" pill variant="success" class="badge">Win-Closed - Excel</b-badge>
              </template>
              <template #cell(payment)="data">
                <b-badge variant="light-success" v-if="data.item.payment == 'yes'" style="font-size: 70%">Payment Made</b-badge>
                <b-badge variant="light-danger" v-if="data.item.payment == 'no' && data.item.status == 'F'" style="font-size: 70%">Payment Pending</b-badge>
                <b-badge variant="light-info" v-if="data.item.payment == 'no' && data.item.status != 'F'" style="font-size: 70%">No Payment</b-badge>
              </template>

              <template #cell(extColourValue)="data">
                <span>{{ data.item.extColourValue ? data.item.extColourValue : 'N/A' }}</span>
              </template>

              <template #cell(intColourValue)="data">
                <span>{{ data.item.intColourValue ? data.item.intColourValue : 'N/A' }}</span>
              </template>
              <template #cell(makeValue)="data">
                <span>{{ data.item.makeValue ? data.item.makeValue : 'N/A' }}</span>
              </template>
              <template #cell(modelValue)="data">
                <span>{{ data.item.modelValue ? data.item.modelValue : 'N/A' }}</span>
              </template>
              <template #cell(modelYearValue)="data">
                <span>{{ data.item.modelYearValue ? data.item.modelYearValue : 'N/A' }}</span>
              </template>
              <template #cell(baseMsrp)="data">
                <span>{{ data.item.baseMsrp ? formatPrice(data.item.baseMsrp, 2) : formatPrice(0) }}</span>
              </template>
              <template #cell(msrpCurrency)="data">
                <span>{{ data.item.msrpCurrency ? data.item.msrpCurrency : 'N/A' }}</span>
              </template>
              <template #cell(origenalMarket)="data">
                <span>{{ data.item.origenalMarket ? data.item.origenalMarket : 'N/A' }}</span>
              </template>

              <template #cell(inventoryLocation)="data">
                <span>{{ data.item.inventoryLocation ? data.item.inventoryLocation : 'N/A' }}</span>
              </template>
              <template #cell(received)="data">
                <b-badge variant="success" v-if="data.item.received == 'yes'" style="font-size: 70%">Vehicle Received</b-badge>
                <b-badge variant="danger" v-if="data.item.received == 'no'" style="font-size: 70%">Vehicle Not Received</b-badge>
              </template>

              <template #cell(consigment)="data">
                <b-badge variant="success" v-if="data.item.consigment == true" style="font-size: 70%">Consignment</b-badge>
                <b-badge variant="danger" v-if="data.item.consigment == false" style="font-size: 70%">Non Consignment</b-badge>
              </template>
              <template #cell(stockNumber)="data">
                <span>{{ data.item.stockNumber ? data.item.stockNumber : 'N/A' }}</span>
              </template>
              <template #cell(vinNo)="data">
                <span>{{ data.item.vinNo ? data.item.vinNo : 'N/A' }}</span>
              </template>

              <template #cell(user)="data">
                <span>{{ data.item.user ? data.item.user : 'N/A' }}</span>
              </template>

              <template #cell(additionalOptions)="data">
                <span>{{ data.item.additionalOptions ? data.item.additionalOptions : 'N/A' }}</span>
              </template>
              <template #cell(additionalOptionsTotal)="data">
                <span>{{ data.item.additionalOptionsTotal ? formatPrice(data.item.additionalOptionsTotal, 2) : formatPrice(0) }}</span>
              </template>
              <template #cell(appraisalNotes)="data">
                <span>{{ data.item.appraisalNotes ? data.item.appraisalNotes : 'N/A' }}</span>
              </template>
              <template #cell(appraisedVehiclePrice)="data">
                <span>{{ data.item.appraisedVehiclePrice ? formatPrice(data.item.appraisedVehiclePrice, 2) : formatPrice(0) }}</span>
              </template>
              <template #cell(creditDue)="data">
                <span>{{ data.item.creditDue ? formatPrice(data.item.creditDue, 2) : formatPrice(0) }}</span>
              </template>
              <template #cell(msrpOptionsValue)="data">
                <span>{{ data.item.msrpOptionsValue ? data.item.msrpOptionsValue : 'N/A' }}</span>
              </template>
              <template #cell(msrpTotal)="data">
                <span>{{ data.item.msrpTotal ? formatPrice(data.item.msrpTotal, 2) : formatPrice(0) }}</span>
              </template>
              <template #cell(notes)="data">
                <span>{{ data.item.notes ? data.item.notes : 'N/A' }}</span>
              </template>
              <template #cell(payoutType)="data">
                <span>{{ data.item.payoutType ? data.item.payoutType : 'N/A' }}</span>
              </template>

              <template #cell(listingPrice)="data">
                <span>{{ data.item.listingPrice ? formatPrice(data.item.listingPrice, 2) : formatPrice(0) }}</span>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data" class="bg-danger">
                <b-dropdown variant="link" no-caret>
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>

                  <b-dropdown-item
                    @click="
                      $router.push({
                        name: 'quote-preview',
                        params: { id: data.item.id },
                      })
                    "
                    v-if="data.item.archive == 'no'"
                    v-show="$Can('purchase_quote_show')"
                  >
                    <feather-icon icon="EyeIcon" />
                    <span class="align-middle ml-50">Quote Preview</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click="
                      $router.push({
                        name: 'inventory-view',
                        params: { id: data.item.inventoryId },
                      })
                    "
                    v-if="data.item.archive == 'no'"
                    v-show="$Can('inventory_show')"
                  >
                    <feather-icon icon="ShoppingCartIcon" />
                    <span class="align-middle ml-50">Vehicle Preview </span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-show="$Can('purchase_quote_edit')"
                    v-if="data.item.priceContractType != 2 && (data.item.status == 'A' || data.item.status == 'B' || data.item.status == 'C' || data.item.status == 'D')"
                    @click="
                      $router.push({
                        name: 'quote-edit',
                        params: { id: data.item.id },
                      })
                    "
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Edit</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>

                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue';
import axiosIns from '@/libs/axios';
import store from '@/store';
import router from '@/router';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    BFormFile,
  },
  mounted() {
    this.getItems();
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    return { selected: ['Admin', 'User'] };
  },
  data() {
    return {
      excelJson: {},
      edit: null,
      newitem: '',
      filter: null,
      filterOn: [],
      perPage: 10,
      loading: false,
      items: [],
      where: router.currentRoute.name,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      fetchPurchase: [],
      listPurchases: [],
      inHeaders: [],
      colWidthFactor: null,
      tempStatusOptions: [
        { label: 'Draft', value: 'A' },
        { label: 'Pending Manager', value: 'B' },
        { label: 'Approved', value: 'C' },
        { label: 'Not Approved', value: 'D' },
        { label: 'Sent To Client', value: 'E' },
        { label: 'Win', value: 'F' },
        { label: 'Loss', value: 'P' },
        { label: 'Canceled Sale', value: 'J' },
        { label: 'Win-Closed', value: 'H' },
      ],
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    onRowSelected(item) {
      if (this.$Can('inventory_show')) {
        router.push({ name: 'quote-preview', params: { id: item[0].id } });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getItems(val) {
      this.loading = true;
      store
        .dispatch('settings/getAllPurchaseQuote', [])
        .then((res) => {
          let filterExcelImportTrue = _.filter(res.data.data, function(o) {
            return o.excelImport;
          });
          this.totalRows = filterExcelImportTrue.length;
          this.fetchPurchase = filterExcelImportTrue;
          this.listPurchases = filterExcelImportTrue;
          var arrayStatus = [];

          filterExcelImportTrue.forEach((element) => {
            arrayStatus.push(element.status);
          });

          this.createTable(res.data.headers, res.data.columns);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createTable(userheaders, headerColumns) {
      if (userheaders) {
        userheaders.forEach((items) => {
          this.colWidthFactor = this.colWidthFactor + headerColumns.find((x) => x.value == items).percentage;
        });

        userheaders.forEach((items) => {
          var columns = headerColumns.find((x) => x.value == items);
          var width = 'width: ' + String(columns.percentage * (95 / this.colWidthFactor)) + '%';
          this.inHeaders.push({
            key: items,
            label: columns.title,
            sortable: true,
            class: 'text-center',
            thStyle: width,
          });
        });
        if (this.$Can('inventory_show') || this.$Can('inventory_edit')) {
          this.inHeaders.push({
            key: 'actions',
            class: 'text-center',
            thStyle: 'width: 5%',
          });
        }
        this.loading = false;
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    importExcelFile(e) {
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        let sheetName = workbook.SheetNames[0];

        let worksheet = workbook.Sheets[sheetName];

        this.excelJson = XLSX.utils.sheet_to_json(worksheet, { defval: null });

        let controlExcelColumn = 0;
        let matchRegex = /(appraisedVehiclePrice|stockNumber|appraisalNotes|sellerType|firstName)/g;

        if (this.excelJson.length > 0 && this.excelJson != {}) {
          for (let x in this.excelJson[0]) {
            if (x.match(matchRegex)) {
              controlExcelColumn += 1;
            }
          }
          if (controlExcelColumn === 5) {
            this.excelJson.forEach((date) => {
              if (!date['quoteDate'].toString().includes('-')) {
                date['quoteDate'] = self.excelDateToJSDate(date['quoteDate']);
              }
              if (!date['completionDate'].toString().includes('-')) {
                date['completionDate'] = self.excelDateToJSDate(date['completionDate']);
              }
            });
            axiosIns
              .post('import-excel-purchase', this.excelJson)
              .then((res) => {
                self.$refs['file-input'].reset();
                self.getItems();
                self.$swal({
                  title: `${res.data.success_added_data == 0 ? 'warning' : 'success'}`,
                  //   text: `${res.data.success_added_data} data successfully added. But ${res.data.error_rows} rows didn't add. Please check your rows and load those rows with a new excel `,
                  html: `
                  <span style="${res.data.success_added_data == 0 ? 'display:none' : ''}">${res.data.success_added_data} data successfully added.</span>
                  <br>
                  <span style="${res.data.error_rows.length > 0 ? '' : 'display:none'}">
                  <span style="${res.data.success_added_data == 0 ? 'display:none' : ''}">But</span> <b style="color:red">${res.data.error_rows}</b> row(s) didn't add. Please check your rows in <u>stock number</u> and load those rows with a new excel
                  </span>`,
                  icon: `${res.data.success_added_data == 0 ? 'warning' : 'success'}`,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });
              })
              .catch((error) => {
                self.$swal({
                  title: 'Error!',
                  text: 'Oops, Something went wrong while loading excel, please try again',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });

                console.log(error);
              });
          } else {
            self.$swal({
              title: 'Error!',
              text: 'Oops, This excel table headers not correct. Make sure the headers are correct',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    },
    excelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      return date_info.getDate() + '-' + (date_info.getMonth() + 1) + '-' + date_info.getFullYear();
    },
  },
};
</script>
